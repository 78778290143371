import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import type { TWFontSizeProp } from '@fragment/ui/src/types/tailwind';

import { ResponsiveOffset } from '../ResponsiveOffset/ResponsiveOffset';

const clickIsWithin = (element: HTMLElement, event: MouseEvent) => {
  const rect = element.getBoundingClientRect();
  return (
    event.clientX > rect.left &&
    event.clientX < rect.right &&
    event.clientY > rect.top &&
    event.clientY < rect.bottom
  );
};

export type ModalProps<FontSizeProp> = {
  onClose: () => void;
  body: React.ReactNode;
  header?: React.ReactNode;
  backDropBlur?: boolean;
  footer?: React.ReactNode;
  'data-testid'?: string;
  fontSize?: TWFontSizeProp<FontSizeProp>;
  noPadding?: boolean;
};

export const Modal = <FontSizeProp,>({
  onClose,
  header,
  footer,
  body,
  backDropBlur = true,
  'data-testid': dataTestId,
  fontSize = 'text-md' as TWFontSizeProp<FontSizeProp>,
  noPadding = false,
}: ModalProps<FontSizeProp>) => {
  useHotkeys('esc', onClose, {
    enableOnFormTags: true,
    scopes: ['modal'],
  });
  const { enableScope, disableScope } = useHotkeysContext();
  useEffect(() => {
    enableScope('modal');
    return () => {
      disableScope('modal');
    };
  }, [disableScope, enableScope]);

  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node) &&
        !clickIsWithin(modalContentRef.current, event)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      data-testid={dataTestId}
      className={clsx('relative z-30', fontSize)}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        data-testid="modal-bg"
        className={clsx(
          `fixed inset-0`,
          backDropBlur && 'bg-main-500 opacity-40'
        )}
      />
      <div className={clsx(`fixed inset-0`)} />
      <div className="fixed inset-0 z-10 w-screen overflow-y-scroll scrollbar-hide flex justify-center">
        <div className="relative top-0">
          <ResponsiveOffset verticalOffset="max-h-f12">
            <div
              ref={modalContentRef}
              data-testid="modal-content"
              className={clsx(
                'fragment-modal-content', // This is used to identify when a modal is open
                'bg-canvas flex flex-col items-center text-center w-fit h-fit min-w-[600px]',
                !noPadding && 'p-f2',
                'mx-f2 border-main-200'
              )}
            >
              {header && <header className="w-full text-left">{header}</header>}

              <main className="relative transform bg-canvas pt-f2 pb-f4 text-left transition-all w-full">
                {body}
              </main>

              {footer && <footer className="w-full">{footer}</footer>}
            </div>
          </ResponsiveOffset>
        </div>
      </div>
    </div>
  );
};
