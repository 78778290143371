import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { FormButtonsProps } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { FormButtons } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';
import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';

type Props = {
  onPageSubmit: (formValues: Record<string, string>) => void;
  formValues: Record<string, string>;
  formButtonsProps: Omit<FormButtonsProps, 'onSubmit'>;
};

type FormData = {
  exportName: string;
  filePrefix: string;
};

export const NewDataExportFormCreateExportPage = ({
  onPageSubmit,
  formValues,
  formButtonsProps,
}: Props) => {
  const {
    getValues,
    handleSubmit,
    formState: { isValid },
    register,
    watch,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      exportName: formValues.exportName,
      filePrefix: formValues.filePrefix,
    },
  });

  // Add a watch to the form to update the codeblock when the form values change
  useEffect(() => {
    watch();
  }, [watch]);

  const onSubmit = useCallback(
    ({ exportName, filePrefix }: FormData) => {
      onPageSubmit({ exportName, filePrefix });
    },
    [onPageSubmit]
  );

  return (
    <form className="space-y-f2">
      <div className="space-y-f2">
        <span>Create Export</span>
        <div className="space-y-f2">
          <TextInput
            label="Export Name"
            size="md"
            padding="p-f0"
            width="w-full"
            flexContainer="space-y-f0"
            placeholder="Name"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('exportName', { required: true })}
          />
          <TextInput
            padding="p-f0"
            size="md"
            label="File Prefix (Optional)"
            flexContainer="space-y-f0"
            width="w-full"
            placeholder="Prefix"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('filePrefix')}
          />
          <TextInputCopy
            id="filepath-format"
            size="md"
            padding="p-f0"
            label="Filepath Format"
            value={`S3://${formValues.bucketName || '<bucket name>'}/${
              getValues('filePrefix') || '<file prefix>'
            }/<data>`}
            width="w-full"
          />
        </div>
      </div>
      <FormButtons
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formButtonsProps}
        disabled={!isValid}
        onSubmit={handleSubmit(onSubmit)}
      />
    </form>
  );
};
